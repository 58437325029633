<template>
  <b-container fluid>
    <b-row>
      <b-col lg="10" sm="10" offset="1">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(addTmp)" @reset.prevent="reset">
                <b-overlay :show="unitLoad">
                  <b-row>
                    <b-col lg="6" sm="6" md="6">
                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="organization"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="stepAssign.org_id"
                          :options="organizationList"
                          :disabled="isDisable"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6" md="6">
                      <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="service_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('service_name.service_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="stepAssign.service_id"
                            :options="serviceList"
                            :disabled="isDisable"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <hr>
                  <b-row>
                    <b-col lg="5" sm="5" md="5">
                      <ValidationProvider name="Step Name" vid="step_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="step_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('step_name.step_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="detailsFormData.step_id"
                            :options="stepList"
                            id="step_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="4" md="4">
                      <ValidationProvider name="order" vid="order" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="order"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('step_assign.order')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="order"
                              v-model="detailsFormData.order"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="button" @click="addTmp()" variant="primary" class="mr-2">{{ $t('globalTrans.add') }}</b-button>
                    </div>
                  </div>
                </b-overlay>
              </b-form>
            </ValidationObserver>
            <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(storeUpdate)" @reset.prevent="reset" enctype="multipart/form-data">
                <br>
                <b-row>
                  <b-table-simple  bordered>
                    <thead>
                      <tr class="">
                        <th scope="col">{{ $t('globalTrans.sl_no') }}</th>
                        <th scope="col">{{ $t('step_name.step_name') }}</th>
                        <th scope="col" width="15%">{{ $t('step_assign.order') }}</th>
                        <th scope="col" >{{ $t('globalTrans.action') }}</th>
                      </tr>
                    </thead>
                    <b-tbody>
                      <slot v-for="(item, index) in stepAssign.details">
                        <b-tr>
                          <td>
                            {{ $n(index + 1) }}
                          </td>
                          <td>
                            {{ getStepName(item.step_id) }}
                          </td>
                          <td>
                            <ValidationProvider name="order" vid="order" rules="required">
                              <b-form-group
                                  slot-scope="{ valid, errors }"
                              >
                              <b-form-input
                              id="order"
                              v-model="item.order"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </td>
                          <td>
                            <b-button type="button" @click="remove(index)" class="btn btn- iq-bg-danger btn-sm text-center ml-1"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </td>
                        </b-tr>
                      </slot>
                    </b-tbody>
                  </b-table-simple>
                </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { stepAssignStore, stepAssignUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  mixins: [commonAuth],
  props: ['item', 'id', 'orgId'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      detailsFormData: {
        step_id: 0,
        order: ''
      },
      stepAssign: {
        org_id: 0,
        service_id: 0,
        details: []
      },
      isDisable: false,
      serviceList: [],
      stepList: [],
      unitLoad: false,
      step_fee: false
    }
  },
  created () {
    this.stepAssign.org_id = this.userCheck()
    if (this.id) {
      this.isDisable = true
      this.stepAssign.org_id = this.orgId
      const tmp = this.getServiceStepData(this.item)
      this.stepAssign.details = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'stepAssign.org_id': function (newVal) {
      this.serviceList = this.getServiceList(newVal)
    },
    'stepAssign.service_id': function (newVal) {
      this.stepList = this.getStepList(newVal)
    }
  },
  methods: {
    getServiceList (orgId) {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
    },
    getStepList (serviceId) {
      return this.$store.state.licenseRegistration.commonObj.stepNamesList.filter(item => item.status === 1 && item.service_id === serviceId)
    },
    addTmp () {
      const stepId = this.detailsFormData.step_id
      const checkService = this.stepAssign.details.find(item => item.step_id === stepId)
      if (this.stepAssign.org_id !== 0 && this.stepAssign.service_id !== 0) {
        this.isDisable = true
      }
      if (typeof checkService === 'undefined') {
        this.stepAssign.details.push(JSON.parse(JSON.stringify(this.detailsFormData)))
        this.detailsFormData.step_id = 0
        this.detailsFormData.order = ''
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      }
    },
    getServiceStepData (data) {
      const detailData = []
      this.stepAssign.service_id = this.id
      Object.entries(data).forEach(([key, value]) => {
        Object.entries(value).forEach(([key, value]) => {
          const stepItem = { step_id: value.step_id, order: value.order }
          detailData.push(stepItem)
        })
      })
      return detailData
    },
    async storeUpdate () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${stepAssignUpdate}/${this.id}`, this.stepAssign)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, stepAssignStore, this.stepAssign)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false

      if (result.exist) {
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
        return
      }

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    remove (key) {
      this.stepAssign.details.splice(key, 1)
    },
    getStepName (stepId) {
      const tmpStep = this.stepList.find(step => step.value === stepId)
      return this.$i18n.locale === 'en' ? tmpStep !== undefined ? tmpStep.text_en : '' : tmpStep !== undefined ? tmpStep.text_bn : ''
    }
  }
}
</script>
<style scoped>
 .table td {
   vertical-align: middle;
 }
</style>
